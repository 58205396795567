import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import LeftImage from '../static/headbar/1.jpg';

export default function Header() {
  const { t, i18n } = useTranslation(),
  [isOpen, setIsOpen] = useState(false),
  [bgColor] = useState('rgba(14, 14, 14, 0.96)'),
  [windowWidth, setWindowWidth] = useState(window.innerWidth),
  [linksColor] = useState('#ffffff'),
  [menuOpen, setMenuOpen] = useState(false),
  links = [
    {link: '#speed-section', text: t('speed_title')}, 
    {link: '#easy-section', text: t('ease_title')}, 
    {link: '#stable-section', text: t('reliability_title')},
    {link: '#contacts', text: t('contact_title')}
  ]

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const openMenu = () => {
    setIsOpen(!isOpen);
    
    // setTimeout(() => {
    //   setIsOpen(false);
    // }, 7000);
  };

  // const handleScroll = () => {
  //   const currentScrollY = window.scrollY;
  //   if (currentScrollY > 20) { // You can adjust this value
  //     setBgColor('#0e0e0e'); // Or any color you want
  //     setLinksColor('#ffffff');
  //   } else {
  //     setBgColor('transparent');
  //     setLinksColor('#0000');
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  );

  function handleScrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for a smooth scroll
    });
  };

  const handleClick = (event) => {
    setMenuOpen(false)
    event.preventDefault();
    const targetId = event.target.getAttribute('href').slice(1);
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      let offsetTop = 0;
      if (window.innerWidth > 768) {
        offsetTop = targetElement.offsetTop - 100;
      }else{
        offsetTop = targetElement.offsetTop;
      } 
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      });
    }
  };

  function linksMenu() {
    return(
      links.map((item, index) => (
        <a href={item.link} key={index}  
          onClick={e => handleClick(e)}
          className={`xl:text-[22px] md:text-[18px] text-[24px] py-2 md:ml-[2.5vw] ml-0`}  style={{ 
          fontFamily: 'PoiretOne-Bold',
          fontWeight: 'bold',
          transition: "color 0.3s ease-in-out",
          color: window.innerWidth > 768 ? linksColor : '#ffffff',

        }}>{item.text}</a>
      ))
    )
  }

  const formatText = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  function languageMenu(borderEnabled) {
    return (
      <div className={`relative flex flex-col items-center pt-1 ${borderEnabled ? 'ml-4' : 'mt-3'}`}>
        <button
          type="button"
          className={`inline-flex justify-center border-[grey] text-md font-medium 
            ${windowWidth > 768 ? `text-[${linksColor}]` : "text-white" }  outline-none 
            ${borderEnabled ? 'border-l-0 ml-2 px-2' : ''} 
            ${bgColor === 'transparent' || windowWidth < 768 ? 'hover:bg-gray-100 hover:text-gray-900' : 'bg-gray-700 text-white'} 
            rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          style={{
            fontFamily: 'PoiretOne-Bold',
            fontWeight: 'bold',                  
          }}        
          onClick={() => openMenu()}
        >
          {i18n.language && i18n.language.toUpperCase()}
          <svg
            className={`-mr-1 ml-2 h-5 w-5 ${isOpen ? 'transform rotate-180' : ''} transition duration-200 ease-in-out`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>              
        {isOpen && (
          <div
            className={`top-6 absolute w-full ${windowWidth > 768 ? "ml-2 px-1" : "pr-1"}`}
            id="menu-button"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex="-1"
          >
            <div className="mt-2 flex flex-col items-start w-full" role="none">
              {['ru', 'ro', 'en'].filter(item => item !== i18n.language).map((lang, index) => (
                i18n.language !== lang && (
                  <button
                    key={lang}
                    href="#"
                    className={`block py-1 w-full pr-6 text-md ${index === 0 ? 'rounded-t-md border-b-[1px]' : 'rounded-b-md'}   
                      ${windowWidth > 768 ? `text-[${linksColor}]` : "text-white" }  
                      ${bgColor === 'transparent' || windowWidth<768 ? 
                        'hover:text-gray-900' : 
                        'bg-gray-700 text-white'} hover:no-underline`}
                    role="menuitem"
                    tabIndex="-1"
                    id={`menu-item-${lang}`}
                    style={{
                      fontFamily: 'PoiretOne-Bold',
                      fontWeight: 'bold',                  
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      switchLanguage(lang);
                    }}
                  >
                    {lang.toUpperCase()}
                  </button>
                )
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }

  const switchLanguage = (lang) => {
    localStorage.setItem('i18nextLng', lang)
    setIsOpen(false);
    changeLanguage(lang);
  };

  return (
    <div className="md:mt-0 mt-[60px] flex w-full sm:h-[47.25vw] h-[60vw]">
      {windowWidth < 768 &&
        <div
          onClick={() => setMenuOpen(!menuOpen)}
          className={`absolute top-0 right-0 w-full cursor-pointer ${menuOpen ? "h-full" : "h-0"} z-10`}
            style={{
              backgroundColor: menuOpen ? 'rgba(0, 0, 0, 0.7)' : 'transparent',
              transition: "background-color 0.3s ease-in-out, left 1s ease-in-out",
            }}
          />
      }
        <header className={`fixed top-0 z-10 flex md:left-0 w-full 2xl:h-[100px] h-[60px]`} 
          style={{
            transition: "background-color 0.3s ease-in-out, left 1s ease-in-out",
            backgroundColor: windowWidth > 768 ? bgColor : 'rgba(14, 14, 14, 0.96)',
          }}
        >
          <div className='flex justify-between items-center w-10/12 m-auto'>
            <h1 onClick={handleScrollToTop} className="cursor-pointer pt-[0.5vw] text-white  2xl:text-[52px] text-[30px] drop-shadow-xl" style={{
              fontFamily: 'GRACETIANS-Cutting',
            }}>neutrino</h1>

            <div className='md:flex hidden flex-row items-center'>
              {linksMenu()}
              {languageMenu(true)}
            <div>

          </div>

        </div>

          <button onClick={() => setMenuOpen(!menuOpen)} className="md:hidden block z-10 space-y-2 cursor-pointer">
            <div className={`w-8 h-0.5 bg-gray-600 transition-all duration-300 ease-out ${menuOpen ? 
              'rotate-45 translate-y-3' : '-translate-y-0.5'
              }`}/>
            <div className={`w-8 h-0.5 bg-gray-600 transition-all duration-300 ease-out ${menuOpen ? 
              'opacity-0' : 'opacity-100'
              }`}/>
            <div className={`w-8 h-0.5 bg-gray-600 transition-all duration-300 ease-out ${menuOpen ? 
              '-rotate-45 -translate-y-2' : 'translate-y-0.5'
              }`}/>
          </button>    
        </div>
      </header>

      <menu className={`md:hidden flex fixed top-[60px] z-10 md:left-0 flex ${menuOpen ? "left-0" : "left-[-100%]" } md:justify-between justify-start md:items-center items-start md:w-full w-[80%] md:h-[80px] h-screen px-[7vw] md:pb-2 pb-auto md:flex-row flex-col`} 
        style={{
          transition: "background-color 0.3s ease-in-out, left 0.6s ease-in-out",
          backgroundColor: window.innerWidth > 768 ? bgColor : 'rgba(14, 14, 14, 0.96)',
        }}
      >
        <div className='flex md:flex-row flex-col items-start py-3'>
          {linksMenu()}
          {languageMenu(false)}
        </div>
      </menu>      

      <div className="relative w-full bg-no-repeat sm:bg-cover bg-[length:120%_160%]" style={{ backgroundImage: `url('${LeftImage}')` }}>
        <p className='text-left sm:ml-[8vw] ml-[2.4vw] md:mt-[10.4vw] sm:mt-10 mt-5 sm:w-4/6 md:text-[1.40vw] sm:text-[1.8vw] text-[2.6vw] leading-[2]' style={{
          fontFamily: 'NotoSansMono-Regular'
        }}>
          {formatText(t('welcome_message'))}
        </p>
      </div>
    </div>
  );
};
