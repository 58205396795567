import React from 'react';
import FooterBack from '../static/footer/3.png';

export default function Footer() {

return (
  <footer id="contacts" className="footer bg-cover bg-no-repeat font-[GRACETIANS] text-[0.83rem] text-white" 
      style={{ backgroundImage: `url(${FooterBack})` }}>
    <div className="flex sm:flex-row flex-col m-auto justify-start sm:items-end py-8 w-10/12 sm:ml-auto"
      style={{
        fontFamily: 'PoiretOne-Bold'
      }}>
        <div className="py-6">
          <h2 className="font-[100] sm:text-2xl text-[1.90rem] leading-6">Contacts</h2>
          <p className="leading-6 mt-[4.5rem] sm:mt-9 sm:text-[1.06rem] text-[1.20rem]">+373 79671341</p>
          <p className="mt-4 sm:text-[1.06rem] text-[1.20rem]">sales@neutrino.md</p>
        </div>

        <div className="py-6 sm:ml-20">
          <h2 className="font-[50] sm:text-2xl text-[1.36rem] leading-6">Neutrino</h2>
          <p className="leading-6 mt-4 sm:text-[1.06rem] text-[1.20rem]">© 2023 Copyright</p>
        </div>

        <div className="py-6 sm:ml-20">
          <a href="/terms" className="leading-6 mt-4 sm:text-[1.06rem] text-[1.20rem] hover:text-gray-300">Terms</a>
        </div>

        <div className="py-6 sm:ml-20">
          <a href="/privacy" className="leading-6 mt-4 sm:text-[1.06rem] text-[1.20rem] hover:text-gray-300">Privacy</a>
        </div>

    </div>
    
  </footer>
);
}