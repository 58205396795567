import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

// 
const getUserLanguage = () => {
  const userLang = navigator.language || navigator.userLanguage;
  return userLang.split('-')[0];
};

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'ru', 'ro'],
    fallbackLng: 'en',
    detection: {
      order: ['path', 'cookie', 'htmlTag', 'localStorage', 'subdomain'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
    react: {
      useSuspense: false,
    },
  });

  const languageDict = {
    'mo':'ro',
    'ro':'ro',
    'ru':'ru'
  }
  
  if (localStorage.getItem('i18nextLng') === null) {
    const userLanguage = getUserLanguage();
    i18n.changeLanguage(languageDict[userLanguage] ? languageDict[userLanguage] : 'en');    
  }

export default i18n;