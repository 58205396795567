import React from 'react';
import { useTranslation } from 'react-i18next';
import AboutImage from '../static/content/2.jpg';

function Privacy() {
  const { t } = useTranslation()

  const formatText = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div id="privacy-section" className="font-custom bg-gray-200">
      <div className="flex lg:flex-row flex-col-reverse justify-between items-center w-10/12 mx-auto py-12">
        <img src={AboutImage} alt="" className="lg:w-[45%] w-[100%]" />
        <div className="flex flex-col justify-center lg:w-6/12 w-8/10">
          <h2 className="pb-10 font-semibold text-2xl" style={{
            fontFamily: 'NotoSansMono-Medium'
          }}>{t('privacy_title')}</h2>
          <p className="pb-8" style={{
            fontFamily: 'NotoSansMono-Regular'
          }}>
            {formatText(t('privacy_text'))}
          </p>
        </div>        
      </div>      
    </div>
  );
}

export default Privacy;