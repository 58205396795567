import React from 'react';

import { useTranslation } from 'react-i18next';

import Slider1 from '../static/slider/1.jpg';
import AboutImage from '../static/content/2.jpg';
import Trends from '../static/content/4.jpg';


export default function Content() {

  const { t } = useTranslation()

  const formatText = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div id="speed-section" className="font-custom bg-gray-200">
      <div className="flex lg:flex-row flex-col-reverse justify-between items-center w-10/12 mx-auto py-12">
        <img src={AboutImage} alt="" className="lg:w-[45%] w-[100%]" />
        <div className="flex flex-col justify-center lg:w-6/12 w-8/10">
          <h2 className="pb-10 font-semibold text-2xl" style={{
            fontFamily: 'NotoSansMono-Medium'
          }}>{t('speed_title')}</h2>
          <p className="pb-8" style={{
            fontFamily: 'NotoSansMono-Regular'
          }}>
            {formatText(t('speed_text'))}
          </p>
        </div>        
      </div>

      <div id="easy-section" className="flex flex-col w-full mx-auto bg-gray-800 text-white">

        <div className="flex lg:flex-row flex-col justify-between items-center w-10/12 mx-auto py-12">
          <div className="flex flex-col justify-center lg:w-6/12 w-8/10">
            <h2 className="pb-10 font-semibold text-2xl" style={{
              fontFamily: 'NotoSansMono-Medium'
            }}>{t('ease_title')}</h2>
            <p className="pb-8" style={{
              fontFamily: 'NotoSansMono-Regular'
            }}>
              {formatText(t('ease_text'))}
            </p>
          </div>
          <img src={Slider1} alt="" className="lg:w-[45%] w-[100%]" />
        </div>
      </div>

      <div id="stable-section" className="flex lg:flex-row flex-col-reverse justify-between items-center w-10/12 mx-auto py-12">
        <img src={Trends} alt="" className="lg:w-[45%] w-[100%]" />
        <div className="flex flex-col justify-center lg:w-6/12 w-8/10">
          <h2 className="pb-10 font-semibold text-2xl" style={{
          fontFamily: 'NotoSansMono-Medium'
        }}>{t('reliability_title')}</h2>
          <p style={{
          fontFamily: 'NotoSansMono-Regular'
        }}>
            {formatText(t('reliability_text'))}
          </p>
        </div>
      </div>
    </div>
  );
};

